import React, { Fragment } from "react"

import { graphql, useStaticQuery } from "gatsby"
import { Banner, Section } from "../components/elements"
import styled from "styled-components"

import {
  Grid,
  Wrapper,
  Media,
  AlignCenter,
  Color,
  Col,
  Type,
} from "../components/utilities"

import { Layout } from "../components/layouts/Layout"

const { Embed } = require("../components/utilities")

const SpecialGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;

  ${Media.below.tablet`
    text-align: center;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  `}
`

const OrangeBar = styled.div`
  display: inline-block;
  height: 3px;
  width: 40px;
  background-color: ${Color.orange};
  margin-bottom: 1rem;

  ${Media.below.tablet`
    display: none;
  `}
`

const BuildingImg = styled.div`
  padding-bottom: 1rem;
  img {
    width: 100%;
  }
`
const Contact = () => {
  const { file } = useStaticQuery(graphql`
    query ContactQuery {
      file(relativePath: { eq: "building.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            src
          }
        }
      }
    }
  `)
  return (
    <Layout title="Contact Pfost Law, Ltd.">
      {({ data }) => (
        <Fragment>
          <Banner title="Contact Pfost Law, Ltd." />
          <Section>
            <Wrapper>
              <SpecialGrid>
                <Col>
                  <BuildingImg>
                    <img src={file.childImageSharp.fluid.src} />
                  </BuildingImg>
                </Col>
                <Col>
                  <Embed>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2986.3074366941087!2d-83.63719438464891!3d41.54094019442486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883c76ff5ec2681b%3A0xb88d4dfd367912af!2s801%20W%20South%20Boundary%20St%2C%20Perrysburg%2C%20OH%2043551!5e0!3m2!1sen!2sus!4v1603820975426!5m2!1sen!2sus"
                      frameborder="0"
                      allowfullscreen=""
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  </Embed>
                </Col>
              </SpecialGrid>
              <br />
              <AlignCenter>
                <h3>Contact Us</h3>
                <OrangeBar />
                <Wrapper maxWidth="550px">
                  <Grid modifiers={["gap"]}>
                    <Col>
                      <h4>Address:</h4>
                      <p>
                        {data.address}
                        <br />
                        {data.address_two}
                      </p>
                      <br />
                      <h4>Email:</h4>
                      <a href={`mailto:${data.email}`}>{data.email}</a>
                    </Col>
                    <Col>
                      <h4>Phone:</h4>
                      <a href={`tel:${data.phone}`}>{data.phone}</a>
                      <br />
                      <br />
                      <h4>Fax:</h4>
                      <p>{data.fax}</p>
                    </Col>
                  </Grid>
                </Wrapper>
              </AlignCenter>
            </Wrapper>
          </Section>
        </Fragment>
      )}
    </Layout>
  )
}

export default Contact
